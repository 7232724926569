.root {
  width: 100%;
  display: flex;
}

.content {
  background-color: var(--color-background-content-area);
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  max-height: 100vh;
  overflow: scroll;
}